import { useLayoutEffect } from 'react';
import { useAppSelector, useAppDispatch } from '@hooks/redux-hooks';
import { setCurrentLanguage } from '@slices/gameStateSlice';

// if the user has an applicable language, we set the default to that
const useUserLocalLanguage = () => {
  const dispatch = useAppDispatch();
  const { currentLanguage, languageCodesUsed } = useAppSelector(
    s => s.gameState,
  );
  // based on a users initial language set on browser, we default to that if available
  // we also check if this is needed, as a user visiting an obviously localized site will not want the content to match keyboard settings
  useLayoutEffect(() => {
    const userDefinedLang = navigator.language || navigator.userLanguage;
    const langIso639 = userDefinedLang.substring(0, 2);
    if (
      languageCodesUsed.includes(langIso639) &&
      currentLanguage !== langIso639 &&
      process.env.GATSBY_USE_LANGUAGE_DETECT === 'true'
    ) {
      // we have a valid browser language, set user to that
      dispatch(setCurrentLanguage({ setTo: langIso639 }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default useUserLocalLanguage;
