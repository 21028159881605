import React from 'react';
import { DarkModal, Flex, Para } from 'workspace-core-ui';
import useTranslation from '@hooks/useTranslation';

type DeactivationModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

const DeactivationModal = (props: DeactivationModalProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Flex>
      <DarkModal
        title={t('Game deactivated')}
        isOpen={props.isOpen}
        isDismissable={false}
        showClose={false}
        onClose={() => props.onClose()}
        modalBodyStyles={{
          style: {
            background: 'hsla(115, 63%, 69%, 1)',
            boxShadow: '8px 3px 3px black',
          },
        }}
      >
        <Flex
          width="100%"
          maxWidth="600px"
          alignItems="left"
          flexDirection="column"
        >
          <Para>{t('Deactivation message')}</Para>
        </Flex>
      </DarkModal>
    </Flex>
  );
};

export default DeactivationModal;
