import checkFeatureFlag from '@utils/checkFeatureFlag';
import { graphql } from 'gatsby';
import {
  HomePage as HomePageV1,
  Head as HeadV1,
} from '../components/home-page-v1';
import {
  HomePage as HomePageV2,
  Head as HeadV2,
} from '../components/home-page-v2';

const isModulesEnabled = checkFeatureFlag('MODULES') === 'FLAG_VALID';
const Head = isModulesEnabled ? HeadV2 : HeadV1;
const HomePage = isModulesEnabled ? HomePageV2 : HomePageV1;

export { Head };
export const query = graphql`
  fragment SvgGetFragment on AirtableConnection {
    nodes {
      data {
        Content {
          data {
            Symbol {
              localFiles {
                childSvg {
                  content {
                    data
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  fragment AbsoluteGetFragment on AirtableConnection {
    nodes {
      data {
        Content {
          data {
            Symbol {
              localFiles {
                publicURL
                name
              }
            }
          }
        }
      }
    }
  }

  fragment GatsbyImageGetFragment on AirtableConnection {
    nodes {
      data {
        Content {
          data {
            Symbol {
              localFiles {
                childImageSharp {
                  gatsbyImageData(placeholder: BLURRED)
                }
              }
            }
          }
        }
      }
    }
  }

  fragment GatsbyImageGetFragmentNoPlaceholder on AirtableConnection {
    nodes {
      data {
        Content {
          data {
            Symbol {
              localFiles {
                childImageSharp {
                  gatsbyImageData(placeholder: NONE)
                }
              }
            }
          }
        }
      }
    }
  }

  query IndexPage {
    mainPageTitleSymbol: allAirtable(
      filter: {
        table: { eq: "Game Elements" }
        data: { Name: { eq: "Main Title" } }
      }
    ) {
      ...SvgGetFragment
      ...GatsbyImageGetFragmentNoPlaceholder
    }
    mainPageBgSymbol: allAirtable(
      filter: {
        table: { eq: "Game Elements" }
        data: { Name: { eq: "Claim Background Image" } }
      }
    ) {
      ...SvgGetFragment
      ...GatsbyImageGetFragmentNoPlaceholder
    }
  }
`;

export default HomePage;
